@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 20px;
  background: rgba(145, 221, 207, 0.9);
  transition: background 0.3s ease, backdrop-filter 0.3s ease;
  z-index: 1000;
  font-family: "Montserrat", sans-serif;
}

.navbar .navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.navbar.scrolled {
  background: rgba(145, 221, 207, 0.7);
  backdrop-filter: blur(10px);
}

.navbar .logo {
  flex: 1;
}

.navbar .logo a {
  font-size: 1.5em;
  color: #f7f9f2;
  text-decoration: none;
}

.navbar .menu-icon {
  display: none;
  font-size: 1.8rem;
  color: #f7f9f2;
  cursor: pointer;
  margin-left: auto; /* Ensure the icon is aligned to the right */
}

.navbar .nav-links {
  display: flex;
  margin-right: 40px;
  gap: 20px;
  list-style: none; /* Remove dots */
}

.nav-links {
  display: flex; /* Ensure the nav links are on the same line */
  align-items: center; /* Center align items vertically */
}

.navbar .nav-links a {
  color: #f7f9f2;
  text-decoration: none;
  font-size: 1em;
}

.navbar .nav-links a:hover {
  color: rgb(53, 78, 198);
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 15px 25px;
  }
  .navbar .menu-icon {
    display: block;
    position: absolute;
    top: 15px;
    right: 65px; /* Adjusted to ensure it's within the screen */
  }

  .navbar .nav-links {
    display: flex;
    flex-direction: column;
    top: 43px;
    width: 100%;
    position: absolute;
    left: -20px;
    background: rgba(145, 221, 207);
    transition: all 0.5s ease;
    align-items: center; /* Center align menu items */
    padding: 20px 0; /* Add padding for spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
    transform: translateX(-100%); /* Hide menu offscreen */
  }

  .navbar .nav-links.active {
    transform: translateX(0); /* Show menu when active */
  }

  .navbar .nav-links li {
    margin: 20px 0;
    text-align: center;
  }

  .navbar .nav-links li a {
    font-size: 1.2em;
  }
}
