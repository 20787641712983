/* Import slick carousel CSS */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  font-family: Arial, sans-serif;
  margin: 0;
  background-color: #f7f9f2;
  padding: 0;
}

header {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 50px 0;
}

header h1 {
  margin: 0;
  font-size: 2.5em;
}

header p {
  margin: 20px 0;
  font-size: 1.2em;
}

header button {
  background: #f04;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

#services {
  padding: 50px 20px;
  text-align: center;
}

.service-item {
  margin: 20px 0;
}

#contact {
  padding: 50px 20px;
  text-align: center;
}

form {
  display: inline-block;
  text-align: left;
}

label {
  display: block;
  margin: 10px 0 5px;
}

input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

/* button[type="submit"] {
  background: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
} */

.content {
  padding-top: 80px; /* Adjust this to match the height of your navbar */
}
