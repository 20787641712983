/* FollowUs.css */

@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700&display=swap");

.follow-us {
  text-align: center;
  padding: 20px 20px;
  background-color: #f7f9f2;
  color: #333;
  font-family: "Kanit", sans-serif;
}

.follow-us h2 {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 20px;
  color: #1a5319;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-icons a {
  color: #333;
  font-size: 1.5em;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
  color: #1a5319;
  transform: scale(1.1);
}
